@font-face {
  font-family: 'Noto Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Noto-Sans-regular/Noto-Sans-regular.eot');
  src: url('../fonts/Noto-Sans-regular/Noto-Sans-regular.eot?#iefix') format('embedded-opentype'),
       local('Noto Sans'),
       local('Noto-Sans-regular'),
       url('../fonts/Noto-Sans-regular/Noto-Sans-regular.woff2') format('woff2'),
       url('../fonts/Noto-Sans-regular/Noto-Sans-regular.woff') format('woff'),
       url('../fonts/Noto-Sans-regular/Noto-Sans-regular.ttf') format('truetype'),
       url('../fonts/Noto-Sans-regular/Noto-Sans-regular.svg#NotoSans') format('svg');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Noto-Sans-700/Noto-Sans-700.eot');
  src: url('../fonts/Noto-Sans-700/Noto-Sans-700.eot?#iefix') format('embedded-opentype'),
       local('Noto Sans Bold'),
       local('Noto-Sans-700'),
       url('../fonts/Noto-Sans-700/Noto-Sans-700.woff2') format('woff2'),
       url('../fonts/Noto-Sans-700/Noto-Sans-700.woff') format('woff'),
       url('../fonts/Noto-Sans-700/Noto-Sans-700.ttf') format('truetype'),
       url('../fonts/Noto-Sans-700/Noto-Sans-700.svg#NotoSans') format('svg');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Noto-Sans-italic/Noto-Sans-italic.eot');
  src: url('../fonts/Noto-Sans-italic/Noto-Sans-italic.eot?#iefix') format('embedded-opentype'),
       local('Noto Sans Italic'),
       local('Noto-Sans-italic'),
       url('../fonts/Noto-Sans-italic/Noto-Sans-italic.woff2') format('woff2'),
       url('../fonts/Noto-Sans-italic/Noto-Sans-italic.woff') format('woff'),
       url('../fonts/Noto-Sans-italic/Noto-Sans-italic.ttf') format('truetype'),
       url('../fonts/Noto-Sans-italic/Noto-Sans-italic.svg#NotoSans') format('svg');
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Noto-Sans-700italic/Noto-Sans-700italic.eot');
  src: url('../fonts/Noto-Sans-700italic/Noto-Sans-700italic.eot?#iefix') format('embedded-opentype'),
       local('Noto Sans Bold Italic'),
       local('Noto-Sans-700italic'),
       url('../fonts/Noto-Sans-700italic/Noto-Sans-700italic.woff2') format('woff2'),
       url('../fonts/Noto-Sans-700italic/Noto-Sans-700italic.woff') format('woff'),
       url('../fonts/Noto-Sans-700italic/Noto-Sans-700italic.ttf') format('truetype'),
       url('../fonts/Noto-Sans-700italic/Noto-Sans-700italic.svg#NotoSans') format('svg');
}
